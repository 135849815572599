import React, { useState } from 'react';
import cn from 'classnames';
import * as s from './tariffsPrice.module.scss';

const PERIOD = {
  MONTH: 'MONTH',
  MONTHS_3: 'MONTHS_3',
  MONTHS_6: 'MONTHS_6',
  YEAR: 'YEAR'
};

const PERIOD_NAME = {
  [PERIOD.MONTH]: 'месяц',
  [PERIOD.MONTHS_3]: '3 месяца',
  [PERIOD.MONTHS_6]: '6 месяцев',
  [PERIOD.YEAR]: 'год'
};

const PRICE_LIST = {
  standard_mini: {
    list: [
      { period: PERIOD.MONTHS_6, price: '11 300' },
      { period: PERIOD.YEAR, price: '19 800' }
    ]
  },
  optimal_mini: {
    list: [
      { period: PERIOD.MONTHS_6, price: '26 800' },
      { period: PERIOD.YEAR, price: '48 750' }
    ]
  },
  base: {
    isFree: true,
    list: []
  },
  standard: {
    list: [
      { period: PERIOD.MONTH, price: '5 500' },
      { period: PERIOD.MONTHS_3, price: '14 800' },
      { period: PERIOD.MONTHS_6, price: '27 400' },
      { period: PERIOD.YEAR, price: '51 500' }
    ]
  },
  optimal: {
    list: [
      { period: PERIOD.MONTH, price: '11 375', priceWithDiscount: '9 670' },
      { period: PERIOD.MONTHS_3, price: '30 870', priceWithDiscount: '26 240' },
      { period: PERIOD.MONTHS_6, price: '53 625', priceWithDiscount: '45 580' },
      { period: PERIOD.YEAR, price: '97 500', priceWithDiscount: '82 900' }
    ]
  }
};

const TariffsPrice = ({ tariff = 'standard', hasDiscount, className }) => {
  const currentTariff = PRICE_LIST[tariff];

  const isFree = currentTariff.isFree;
  const prices = currentTariff.list;

  const lastIndex = prices.length - 1;
  const [currentPriceId, setCurrentPriceId] = useState(lastIndex);


  const freePrice = (
    <div className={s.priceValues}>
      <span className={cn(s.price, s.priceCurrent)}>
        0 &#8381;
      </span>
    </div>
  );

  const paidPrices = (
    <div className={s[className]}>
      <p className={s.tariffPeriod}>
        Срок действия*
      </p>

      <div className={s.pricesWithSwitcher}>
        <div className={s.switcher}>
          {
            prices.map((price, index) => (
              <button
                onClick={() => setCurrentPriceId(index)}
                key={index}
                className={cn(s.buttonSwitcher, (currentPriceId === index && s.activeButton).toString())}
              >
                { PERIOD_NAME[price.period] }
              </button>
            ))
          }
        </div>

        <div className={s.priceValues}>
          {
            hasDiscount &&
            <span className={cn(s.price, s.priceOld)}>
              { prices[currentPriceId]?.price } &#8381;
            </span>
          }
          <span className={cn(s.price, s.priceCurrent)}>
            {
              hasDiscount
              ? prices[currentPriceId]?.priceWithDiscount
              : prices[currentPriceId]?.price
            } &#8381;
            {
              tariff === 'optimal' && <span className={s.priceCurrentNote}>**</span>
            }
          </span>
        </div>
      </div>

      <div className={s.pricesWithoutSwitcher}>
        {
          prices.map((price, index) => (
            <div
              className={s.priceInPricesList}
              key={index}
            >
              <div className={s.priceValues}>
                {
                  hasDiscount &&
                  <span className={cn(s.price, s.priceOld)}>
                    { price.price } &#8381;
                  </span>
                }
                <span className={cn(s.price, s.priceCurrent)}>
                  { hasDiscount ? price.priceWithDiscount : price.price } &#8381;
                </span>
              </div>

              <span className={s.monthInPricesList}>
                { PERIOD_NAME[price.period] }
              </span>
            </div>
          ))
        }
      </div>
    </div>
  );

  return isFree ? freePrice : paidPrices;
};

export default TariffsPrice;
